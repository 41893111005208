import type { SupabaseClient } from '@supabase/supabase-js';
import type { Database, Tables } from '$lib/db';
import type {
	Location,
	Listing,
	NearbyListing,
	ListingSearchResult,
	Category,
	Profile
} from '$lib/types';

export type ListingRow = Tables<'listings'>;
export type ListingInsert = Database['public']['Tables']['listings']['Insert'];
export type ListingUpdate = Database['public']['Tables']['listings']['Update'];

export default class ListingsRepository {
	private supabase: SupabaseClient;

	constructor(supabase: SupabaseClient) {
		this.supabase = supabase;
	}

	async isOwnerOfListing(userId: string, listingId: number) {
		const { data, error } = await this.supabase
			.from('listings')
			.select('id')
			.eq('user_id', userId)
			.eq('id', listingId)
			.single();

		if (error) {
			if (error.code === 'PGRST116') {
				return false;
			}
			throw error;
		}

		return data !== null;
	}

	async getOrCreateDraft(): Promise<Listing> {
		return this.supabase.rpc('get_or_create_draft_listing').then(({ data, error }) => {
			if (error) {
				console.error('Error receiving or creating draft listing:', error);
				throw error;
			}

			if (!data) {
				throw new Error('could not receive draft listing');
			}

			const location: Location = {
				place: data.location_name == null ? 'Unknown Location' : data.location_name,
				lat: Number(data.location_lat),
				lng: Number(data.location_lng)
			};

			let category: Category = {
				id: data.category_id,
				name: data.category_name,
				slug: data.category_slug,
				path: data.category_path
			};

			let profile: Profile = {
				id: data.profile_id,
				fullName: data.full_name,
				username: data.username
			};

			return {
				id: data.id,
				title: data.title,
				description: data.description,
				createdBy: profile,
				category: category,
				location: location,
				contactInfo: data.contact_info ? data.contact_info : {},
				isFavorite: false,
				images: data.images.map((image: any) => ({
					id: image.id,
					imageUrl: image.image_url,
					isPrimary: image.is_primary
				}))
			};
		});
	}

	async getById(id: number, userId?: string): Promise<Listing | null> {
		return this.supabase
			.rpc('get_detailed_listing', { listing_id: id, current_user_id: userId })
			.then(({ data, error }) => {
				if (error) {
					console.error('Error retrieving listing:', error);
					throw error;
				}

				if (!data) {
					return null;
				}

				const location: Location = {
					place: data.location_name,
					lat: data.location_lat,
					lng: data.location_lng
				};

				let category: Category = {
					id: data.category_id,
					name: data.category_name,
					slug: data.category_slug,
					path: data.category_path
				};

				let profile: Profile = {
					id: data.profile_id,
					fullName: data.full_name,
					username: data.username
				};

				if (data.images == null || data.images == undefined) {
					data.images = [];
				}

				return {
					id: data.id,
					title: data.title,
					description: data.description,
					createdBy: profile,
					category: category,
					location: location,
					contactInfo: data.contact_info ? data.contact_info : {},
					isFavorite: false,
					images: data.images.map((image: any) => ({
						id: image.id,
						imageUrl: image.image_url,
						isPrimary: image.is_primary
					}))
				};
			});
	}

	async listingInView(
		min_lat: number,
		min_long: number,
		max_lat: number,
		max_long: number
	): Promise<NearbyListing[]> {
		const { data, error } = await this.supabase
			.rpc('listings_in_view', {
				min_long,
				min_lat,
				max_long,
				max_lat
			})
			.returns<NearbyListing[]>();

		if (error) {
			throw new Error(error.message);
		}

		return data;
	}

	async searchByTitleAndDescription(query: string): Promise<ListingSearchResult[]> {
		const { data, error } = await this.supabase
			.rpc('search_listings', {
				prefix: query
			})
			.returns<ListingSearchResult[]>();

		if (error) {
			throw new Error(error.message);
		}

		return data;
	}
}
